<template>
    <div class="small-fs pdf" :class="`color-${sell.company.prefix}`" :style="{ height: pdfHeight + 'px' }">
        <div :id="'page_' + i" :ref="'page_' + i" v-for="(group, i) in itemGroups" :key="i">
            <div v-if="i > 0" class="html2pdf__page-break"/>
            <div class="pdf__header">
                <div class="row">
                    <div class="col-4">
                        <img class="logo" v-if="sell.company" :src="'/logos/' + sell.company.prefix + '.png'">
                    </div>
                    <div class="offset-4 col-4 mt-2 text-end">
                        <img class="logo" src="/certif/smabtp.jpg">
                        <img class="logo" src="/certif/rge-qualibat.png" v-if="sell.company.prefix != '3M'">
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col-12 text-center">
                        <div class="title">
                            {{ title }}<br>
                            N°{{ SellService.getNumber(sell.sellId, sell.created_date, sell.company) }}<br>
                        </div>
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col-7">
                        <div class="info info--medium">
                            {{ sell.customer.firstname }} {{ sell.customer.lastname }}<br>
                            {{ sell.customer.address }}, {{ sell.customer.postcode }} {{ sell.customer.city }}<br>
                            {{ (sell.customer.phone != '') ? sell.customer.phone : '' }}{{ (sell.customer.mobile != '') ? (sell.customer.phone != '' ? ' - ' : '') + sell.customer.mobile : '' }}<br>
                            {{ sell.customer.email }}
                        </div>
                    </div>
                    <div class="col-5">
                        <div class="info info--medium">
                            {{ sheetType }}<br>
                            Date : {{ SellService.getDate(sell.created_date) }}<br>
                            Date des travaux au plus tard : {{ SellService.getDate(sell.workDate) }}<br>
                            <b v-if="sell.workers && sell.workers != ''">Techniciens :</b> {{ sell.workers }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="pdf__content" v-if="group.length > 0">
                <div class="row mt-2">
                    <div class="col-12">
                        <div>
                            <table class="table table-nowrap table-centered mb-0 table-slim services">
                                <thead>
                                    <tr>
                                        <th>Prestation</th>
                                        <th>Description</th>
                                        <th class="text-end">Unité</th>
                                        <th class="text-end">Prix U. HT</th>
                                        <th class="text-end">Quantité</th>
                                        <th class="text-end">Total HT</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="item in group" :key="item.id">
                                        <td class="service-title">
                                            <strong>{{ item.title }}</strong>
                                        </td>
                                        <td class="description">{{ item.description }}</td>
                                        <td class="text-end">{{ (item.service.unit == 'fixed') ? 'Forfait' : item.service.unit }}</td>
                                        <td class="text-end">{{ item.price | price }}</td>
                                        <td class="text-end">{{ item.quantity }}</td>
                                        <td class="text-end">{{ item.total | price }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div class="all-page-footer" :style="{ height: (1080*(i+1)) + 'px' }" ref="pageFooter">
                <div class="col-12">
                    <div class="info info--small mt-3 text-center">
                        {{ sell.company.legal }}<br>
                        {{ sell.company.name }} - {{ sell.company.address }} {{ sell.company.postcode }} <span class="uppercase">{{ sell.company.city }}</span> - {{ sell.company.telephone }} - {{ sell.company.email }}
                    </div>
                </div>
                <div class="page-number-container">
                    <div class="page-number">Page {{ i+1 }} sur {{ pageCount }}</div>
                </div>
            </div>
        </div>
        <div ref="afterServices">
            <div class="row mt-2" v-if="sell.details && sell.details != ''">
                <div class="col-12">
                    <div class="section--bordered p-1">
                        <div class="title">Remarques/détails</div>
                        <div class="comment mt-1">{{ sell.details }}</div>
                    </div>
                </div>
            </div>
            <div class="row mt-2">
                <div class="col-12">
                    <div class="section--bordered p-1">
                        <div class="row">
                            <div class="col-6">
                                <div class="custom-control custom-checkbox">
                                    <div class="form-check">
                                        <input v-model="sell.tenyear" type="checkbox" class="form-check-input" id="tenyear">
                                        <label class="form-check-label" for="tenyear">Garantie décennale « 10 ans »</label>
                                    </div>
                                </div>
                                <div class="custom-control custom-checkbox">
                                    <div class="form-check">
                                        <input v-model="sell.twoyear" type="checkbox" class="form-check-input" id="twoyear">
                                        <label class="form-check-label" for="twoyear">Responsabilité civile 2 ans</label>
                                    </div>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="info">Suivi annuel : {{ sell.followup }}</div>
                                <div class="info">Frais de dossier : {{ sell.fees }}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 mt-2">
                    <div class="custom-control custom-checkbox d-inline-block me-3">
                        <div class="form-check">
                            <input :checked="sell.payment_method == 'Comptant'" type="checkbox" class="form-check-input">
                            <label class="form-check-label">Paiement comptant</label>
                        </div>
                    </div>
                    <div class="custom-control custom-checkbox d-inline-block">
                        <div class="form-check">
                            <input :checked="sell.payment_method == 'Financement'" type="checkbox" class="form-check-input">
                            <label class="form-check-label">Dossier de financement <span v-if="sell.payment_method == 'Financement'">: {{ sell.organism }}</span></label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="table-responsive" v-if="sell.payment_method == 'Financement'">
                        <table class="table table-nowrap table-centered mb-0 boxed table--small">
                            <thead>
                                <tr>
                                    <th>Prix comptant</th>
                                    <th>Versement initial</th>
                                    <th>Montant du crédit</th>
                                    <th>Mensualité sans assu.</th>
                                    <th>Mensualité avec assu.</th>
                                    <th width="85">Coût total de l'achat à crédit</th>
                                    <th>Durée</th>
                                    <th width="80">Taux débiteur fixe</th>
                                    <th>TAEG</th>
                                    <th>Nombre de mensualité</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{{ sell.financialSection.price | price }}</td>
                                    <td>{{ sell.financialSection.deposit | price }}</td>
                                    <td>{{ sell.financialSection.credit | price }}</td>
                                    <td>{{ sell.financialSection.monthly | price }}</td>
                                    <td>{{ sell.financialSection.monthlyWithIns | price }}</td>
                                    <td>{{ sell.financialSection.total | price }}</td>
                                    <td>{{ sell.financialSection.duration }}</td>
                                    <td>{{ sell.financialSection.rate | percent }}</td>
                                    <td>{{ sell.financialSection.taeg | percent }}</td>
                                    <td>{{ sell.financialSection.months }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <div class="pdf__footer" ref="pdfFooter">
            <div class="row">
                <div class="col-12 mt-3">
                    <div class="custom-control custom-checkbox d-inline-block me-3">
                        <div class="form-check">
                            <input type="checkbox" class="form-check-input">
                            <label class="form-check-label">Je certifie avoir lu et accepté les conditions générales de la société {{ sell.company.name }}.</label>
                        </div>
                    </div>
                </div>
                <div class="col-6">
                    <div class="info info--small mt-2">
                        Selon les informations fournies par nos soins, cette commande est calculée avec un taux de TVA à 10%, ce taux ne s'applique que pour un local de plus de deux ans, affecté totalement ou principalement à l'habitation (plus de 50% de la superficie). Elle ne pourra être applicable qu'après renvoi de l'attestation jointe.
                    </div>
                    <div class="info mt-3">
                        Travaux effectués au prix de<br>
                        > <strong>TOTAL HT</strong> .................................................................................................. <br>
                        > <strong>TOTAL TTC</strong> ................................................................................................
                    </div>
                </div>
                <div class="col-6">
                    <table class="table table-nowrap table-centered mb-0 table-slim mt-2">
                        <tbody>
                            <tr>
                                <td scope="row">
                                    <strong>Total HT</strong>
                                </td>
                                <td>{{ sell.total | price }}</td>
                                <td>
                                        <div class="custom-control custom-checkbox d-inline-block me-3">
                                        <div class="form-check">
                                            <input type="checkbox" class="form-check-input">
                                            <label class="form-check-label">Chèques</label>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td scope="row">
                                    <strong>TVA</strong>
                                </td>
                                <td>{{ sell.totalTaxes | price }}</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td scope="row">
                                    <strong>Total TTC</strong>
                                </td>
                                <td>{{ sell.totalWithTaxes | price }}</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td scope="row">
                                    <strong>Acompte</strong>
                                </td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td scope="row">
                                    <strong>Solde</strong>
                                </td>
                                <td></td>
                                <td></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="row">
                <div class="col-6 mt-3">
                    <div class="info mt-2">
                        En toutes lettres .......................................................................................................................<br>
                        .......................................................................................................................
                    </div>
                    <div class="info mt-4">
                        Fait à ..............................................................................................................
                    </div>
                    <div class="info mt-2">
                        Le ...................................................................................................................
                    </div>
                </div>
                <div class="col-6 mt-3">
                    <div class="section--bordered p-1 info pb-6">
                        <div class="info--large">SIGNATURE DU CLIENT</div>
                        <small>Précédée de la mention "Bon pour accord"</small><br>
                        <small>Devis reçu avant l'exécution des travaux</small>
                    </div>
                </div>
                <div class="col-12">
                    <div class="small-square">Paraphe</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import SellService from "../../services/sell.service";

    export default {
        name: 'QuotationPdf',
        data() {
            return {
                countItemsFirstPage: 5,
                countItemsLastPage: 3,
                SellService: SellService,
                itemGroups: [...this.sell.sellItems]
            }
        },
        props: ['sell', 'sellIsLoaded', 'title', 'sheetType'],
        beforeMount() {
            this.updateItemGroups();
        },
        mounted() {
            var lastPageId = 'page_' + (this.itemGroups.length - 1);
            if (this.$refs[lastPageId]) {
                var totalHeight = this.$refs[lastPageId][0].clientHeight + this.$refs.afterServices.clientHeight + this.$refs.pdfFooter.clientHeight + 70;
                var isOverlaping = (totalHeight > 1080);
                while (isOverlaping) {
                    this.countItemsLastPage--;
                    this.updateItemGroups();
                    lastPageId = 'page_' + (this.itemGroups.length - 1);
                    if (this.$refs[lastPageId]) {
                        totalHeight = this.$refs[lastPageId][0].clientHeight + this.$refs.afterServices.clientHeight + this.$refs.pdfFooter.clientHeight + 70;
                        isOverlaping = (totalHeight > 1080);
                    } else {
                        isOverlaping = false;
                    }
                }
            }
        },
        methods: {
            updateItemGroups() {
                this.itemGroups = [];
                if (this.sell.sellItems.length <= this.countItemsLastPage) {
                    this.itemGroups.push(this.sell.sellItems);
                } else {
                    let count = this.sell.sellItems.length;
                    let start = 0;
                    while (count > this.countItemsLastPage) {
                        this.itemGroups.push(this.sell.sellItems.slice(start, start + this.countItemsFirstPage));
                        count -= this.countItemsFirstPage;
                        start += this.countItemsFirstPage;
                    }
                    this.itemGroups.push(this.sell.sellItems.slice(start, start + this.countItemsLastPage));
                }
            },
        },
        computed: {
            pageCount() {
                return this.itemGroups.length;
            },
            pdfHeight() {
                return 1080 * this.pageCount;
            }
        }
    }
</script>

<style scoped lang="scss">
    .small-fs {
        font-size: 0.7rem;
    }

    .color-FP {
        $color: #354E85;

        tbody {
            border-top: 1px solid $color !important;
        }

        .section--bordered, table, .small-square {
            border: 1px solid $color;
        }
    }

    .color-MP {
        $color: #0078B3;

        tbody {
            border-top: 1px solid $color !important;
        }

        .section--bordered, table, .small-square {
            border: 1px solid $color;
        }
    }

    .color-3M {
        $color: #70B632;

        tbody {
            border-top: 1px solid $color !important;
        }

        .section--bordered, table, .small-square {
            border: 1px solid $color;
        }
    }

    .color-PH {
        $color: #AE0C21;

        tbody {
            border-top: 1px solid $color !important;
        }

        .section--bordered, table, .small-square {
            border: 1px solid $color;
        }
    }

    .small-square {
        width: 40px;
        height: 40px;
        position: absolute;
        right: 10px;
        top: 5px;
        font-size: 7px;
        padding: 3px;
    }

    .logo {
        max-width: 300px;
        max-height: 80px;
    }

    .info, .title, label, td {
        color: black;
        line-height: 1.5;
    }

    .info {
        &--small {
            font-size: 10px;
        }

        &--medium {
            font-size: 0.8rem;
        }

        &--large {
            font-size: 18px;
        }
    }

    .title {
        font-weight: bold;
        font-size: 0.8rem;
    }

    thead {
        color: black;
    }

    .comment {
        white-space: pre-wrap;
    }

    .table--small {
        font-size: 8px;
        th, td {
            padding: 2px;
        }
    }

    .pb-6 {
        padding-bottom: 6em !important;
    }

    .uppercase {
        text-transform: uppercase;
    }

    .row {
        break-inside: avoid;
    }

    .services {
        vertical-align: baseline !important;

        td {
            vertical-align: baseline !important;
        }

        th, td {
            padding: 0.25rem 0.5rem !important;
        }
    }

    td.service-title, td.description {
        white-space: pre-wrap;
        max-width: 200px;
    }

    .pdf {
        position: relative;

        &__footer {
            position: absolute;
            bottom: 80px;
            left: 0;
        }
    }

    .all-page-footer {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        display: flex;
        justify-content: flex-end;
        flex-direction: column;
    }

    .page-number-container {
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
    }
</style>